<template>
  <v-dialog
    class="dialog-remove"
    overlay-color="primary"
    persistent
    :value="value"
    width="400px"
  >
    <v-card>
      <v-system-bar dark color="primary">
        <v-icon class="mr-2">mdi-delete</v-icon>
        <span>{{ $t('DIALOG.REMOVE_TITLE') }}</span>
        <v-spacer/>
        <v-icon
          small
          class="clickable"
          @click="$emit('close')"
        >
          mdi-close
        </v-icon>
      </v-system-bar>
      <div class="dialog-remove-header">
        <div class="text-center pa-10 pt-4 pb-4 text-h6">
          <span>{{ $tc('DIALOG.REMOVE_TEXT', items.length ) }}</span>
        </div>
      </div>
      <div class="toolbar-separator primary ml-4 mr-4"/>

      <div class="dialog-remove-content">
        <v-list dense class="pa-0 pr-4 pl-4">
          <template v-for="(item,i) in items.slice(0,100)" >
            <v-list-item :key="i">
              <v-list-item-title class="primary--text"><strong>{{ format(item) }}</strong></v-list-item-title>
              <v-list-item-action>
                <v-icon size="18" @click="selectItem(i)">mdi-close-circle</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="'div' + i" />
          </template>
        </v-list>
      </div>

      <v-divider />
      <div class="dialog-remove-footer d-flex pa-4">
        <v-btn
          depressed
          large
          rounded
          @click="close"
        >
          <v-icon class="mr-2">mdi-arrow-left</v-icon>
          {{ $t('DIALOG.REMOVE_BACK_BTN') }}
        </v-btn>
        <v-spacer />
        <v-btn
          depressed
          color="error"
          large
          rounded
          @click="confirm"
        >
          <v-icon class="mr-2">mdi-delete</v-icon>
          {{ $t('DIALOG.REMOVE_CONFIRM_BTN') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<style>
 .dialog-remove-content {
   height: 50vh;
   overflow-y: auto;
   overflow-x: hidden;
 }
</style>

<script>
const _getValueFromKey = (key, value) => {
  key = key.split('.')
  for (let i = 0, len = key.length; i < len; i++) {
    if (value[key[i]]) value = value[key[i]]
    else return ''
  }
  return value
}

export default {
  name: 'DialogRemove',

  data: () => ({
    selectedItems: []
  }),

  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    },
    itemText: {
      type: String,
      default: 'id'
    }
  },

  watch: {
    items (n) {
      this.selectedItems = [...n]
    }
  },

  methods: {

    format (item) {
      return _getValueFromKey(this.itemText, item)
    },

    selectItem (index) {
      if (index !== -1) {
        this.selectedItems.splice(index, 1)
      }
      this.$emit('select', this.selectedItems)
      if (this.selectedItems.length === 0) this.close()
    },

    close () {
      this.$emit('close')
    },

    confirm () {
      this.$emit('confirm', this.items)
      this.close()
    },
  }
}
</script>
