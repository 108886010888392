<template>
  <div>
    <v-navigation-drawer
      :right="right"
      :value="value"
      :width="width"
      height="100vh"
      hide-overlay
      stateless
      temporary
      fixed
      @input="v => !v ? $emit('close', v) : ''"
    >
      <div class="side height-100" v-if="value">
        <Loading :value="loading"/>
        <v-lazy :value="value" class="height-100">
          <div class="side-inner height-100 d-flex flex-column">
            <!--
            <div class="secondary side-header">
              <v-toolbar
                color="transparent"
                flat
                v-if="$slots.header || title || icon"
                height="90px"
              >
                <v-avatar class="mr-4" v-if="icon">
                  <v-icon size="24">{{ icon }}</v-icon>
                </v-avatar>
                <v-toolbar-title>
                  <div v-if="subtitle" class="v-toolbar__subtitle"><span>{{ subtitle }}</span></div>
                  <strong class="text-h5 text-capitalize">{{ title }}</strong>
                  <div v-if="items.length > 1" class="v-toolbar__subtitle muted">
                    <span>+ {{ $tc('COMMON.ITEMS', items.length - 1) }}</span></div>
                </v-toolbar-title>
                <v-spacer/>
                <template v-if="closable">
                  <v-btn
                    icon
                    @click="$emit('close')"
                    large
                    class="ml-3 mr-0"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-toolbar>
              <v-divider/>
            </div>
            -->

            <div v-if="$slots.default || component" class="side-content">
              <component
                v-if="componentAsync"
                :is="componentAsync"
                :props="props"
                :items="items"
                :ids="ids"
                @loading="handleLoading"
                @close="close"
                @update="update"
                @select="select"
                @create="create"
                @remove="remove"
              />
              <slot v-if="$slots.default"/>
            </div>
          </div>
        </v-lazy>
      </div>
    </v-navigation-drawer>
    <v-overlay
      v-if="value"
      color="primary"
      @click.native="$emit('close')"
    />
  </div>
</template>

<style>

.side {
  position: relative;
}

.side-content {
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
}


.side-content .form {
  height: 100%;
}

.side-content .form > .v-form {
  /* height: calc(100vh - 153px); */
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.side .v-avatar {
}

.side .back-btn {
  background: rgba(255, 255, 255, .08);
}

.side .v-toolbar__title .v-icon {
  vertical-align: text-bottom;
}

.side .v-toolbar__subtitle {
  font-size: 12px !important;
  line-height: 16px;
  text-transform: uppercase;
}

</style>

<script>
import Loading from '@/components/Loading'

const _componentsCache = new Map()

export default {
  name: 'Side',

  components: {
    Loading
  },

  data: () => ({
    componentAsync: null,
    loading: false,
  }),

  props: {
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '680px'
    },
    closable: {
      type: Boolean,
      default: true
    },
    icon: {
      type: [String, Boolean],
      default: false
    },
    title: {
      type: [String, Boolean],
      default: false
    },
    subtitle: {
      type: [String, Boolean],
      default: false
    },
    component: {
      type: [String, Object, Boolean],
      default: false
    },
    props: {
      type: [Object, Boolean],
      default: false
    },
    items: {
      type: [Array, Boolean],
      default: () => ([])
    },
    ids: {
      type: [Array],
      default: () => ([])
    },
    right: {
      type: [Boolean],
      default: true
    }
  },

  mounted () {
    if (this.component) {
      this.loadComponent()
    }
  },

  methods: {

    loadComponent () {
      const component = this.component
      if (component) {
        if (!_componentsCache.has(component)) {
          import(`../${component}.vue`)
            .then(component => {
              _componentsCache.set(component, component.default)
              this.componentAsync = component.default
            })
        } else {
          this.componentAsync = _componentsCache.get(component) || null
        }

      }
    },

    handleLoading (v) {
      this.loading = !!v
    },

    close (data) {
      this.$emit('close', data)
    },

    update (data) {
      this.$emit('update', data)
    },

    select (data) {
      this.$emit('select', data)
    },

    create (data) {
      this.$emit('create', data)
    },

    remove (data) {
      this.$emit('remove', data)
    },

  }
}
</script>
